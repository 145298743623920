import React, { useState,useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

import './Confirm.css'; // 你的样式文件
import InscriptionsList from './InscriptionsList'
import ReceiveInput from './ReceiveInput'
import FeeContainer from './FeeContainer' 
const Confirm = ({ data }) => {
  // 使用 useNavigate 钩子来处理页面跳转
  const navigate = useNavigate();
  // const inscriptionsData = [
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  //   '{"p":"brc-20","tick":"eorb","op":"mint","amt":"10"}',
  // ];
  
  const location = useLocation();
  const inscriptionsData = location.state?.inscriptionsData; // Use optional chaining in case state is undefined


  // 处理返回按钮的点击事件
  const goBack = () => {
    navigate(-1); // 返回上一页
  };


  return (
    <div className="confirm-page">
      <div className="confirm-page-container">
        <button onClick={goBack} className="back-button">← Mint</button>
        
        <InscriptionsList inscriptions={inscriptionsData} />
        <ReceiveInput defaultAddress="bc1q..." />
        <FeeContainer inscriptions={inscriptionsData} />
        {/* 其他可能的确认页面内容 */}
      </div>
    </div>
  );
};

export default Confirm;
