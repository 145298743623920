import React, { useState, useEffect } from 'react';
import './App.css'; // Your App's CSS
import NavBar from './NavBar'; // Import the NavBar component
import FilterBar from './FilterBar'; // Import the FilterBar component
import List from './List'; // Import the List component
import Header from './Header'; // Import the Header component
import MintRankComponent from './MintRankComponent';
import RuneTransactions from './RuneTransactions';
import Big from 'big.js';

import {
  Box,
  Flex,
  Text,
  Button,
  Progress,
  Stack,
  Tag,
  useColorModeValue,
} from '@chakra-ui/react';

const mintData = [
  {
    tick: 'eorb',
    mints: 352932,
    holders: 5372,
    segments: [
      { value: 50, color: 'blue' },    // Blue segment covering 50% of the total
      { value: 30, color: 'green' },   // Green segment covering 30% of the total
      { value: 20, color: 'red' },     // Red segment covering 20% of the total
    ],
  }
  // ... more items
];
/**
 * type UserRuneTx struct {
  RuneName  string          `json:"rune_name"`
  RuneId    string          `json:"rune_id"`
  Price     decimal.Decimal `json:"price"`
  Amount    decimal.Decimal `json:"amount"`
  TxId      string          `json:"tx_id"`
  InIdx     int             `json:"in_idx"`
  TotalSats int64           `json:"total_sats"`
}

type UserRuneTxs struct {
  RuneName string       `json:"rune_name"`
  RuneTxs  []UserRuneTx `json:"rune_txs"`
}
 */
const runeTxsData = [{
  rune_name: 'ExampleRune1',
  rune_txs: [
    {
      rune_name: 'ExampleRune1',
      rune_id: 'rune_001',
      price: new Big(10.99),
      amount: new Big(5),
      tx_id: 'tx123',
      in_idx: 1,
      total_sats: 100000
    },
    {
      rune_name: 'ExampleRune1',
      rune_id: 'rune_001',
      price: new Big(10.99),
      amount: new Big(5),
      tx_id: 'tx123',
      in_idx: 1,
      total_sats: 100000
    },
    // 更多交易...
  ]
}, {
  rune_name: 'ExampleRune1',
  rune_txs: [
    {
      rune_name: 'ExampleRune1',
      rune_id: 'rune_001',
      price: new Big(10.99),
      amount: new Big(5),
      tx_id: 'tx123',
      in_idx: 1,
      total_sats: 100000
    },
    {
      rune_name: 'ExampleRune1',
      rune_id: 'rune_001',
      price: new Big(10.99),
      amount: new Big(5),
      tx_id: 'tx123',
      in_idx: 1,
      total_sats: 100000
    },
    // 更多交易...
  ]
}];


// Home组件
const Home = () => {
  const [filter, setFilter] = useState('1D'); // 设置默认 filter 为 '1d'
  const [runeTxsData, setRuneTxsData] = useState([]);

  // handleFilterChange 更新 filter 状态
  const handleFilterChange = (newFilter) => {
    console.log('Filter changed to:', newFilter);
    setFilter(newFilter); // 更新 filter 状态
    fetchData(newFilter);
  };

  const periodMappings = {
    '1 block': '1b',
    '3 blocks': '3b',
    '10 blocks': '10b',
    '1D': '1D',
    '3D': '3D',
    '7D': '7D'
  };

  const fetchDataFromAPI = async (filter) => {
    // 构建 API 的 URL，包括查询参数
    const filterParam = periodMappings[filter];
    const url = new URL('https://api.mint365.io:8080/api/v1/tx/pending');
    // url.searchParams.append('period', filterParam); // 添加期间参数，例如 '1D'
    // url.searchParams.append('p', 'brc20'); // 如果有其他固定参数，也可以在这里添加

    try {
      const response = await fetch(url); // 发送 GET 请求到 API
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const fetchData = async (filterValue) => {
    try {
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await fetchDataFromAPI(filterValue); // 使用当前的 filter 作为参数
      console.log(response.data);
      setRuneTxsData(response.data); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

  // 使用 useEffect 钩子根据 filter 的变化获取数据
  useEffect(() => {
    fetchData(filter);
  }, []); // 空依赖数组保证只在挂载时运行

  return (
    <Box>
      <RuneTransactions runeTxsData={runeTxsData} />
    </Box>
    // <div className="home">
    //   {/* <Header /> */}
    //   <MintRankComponent />
    //   <NavBar />
    //   <FilterBar onFilterChange={handleFilterChange} />

    //   <List data={mintData} />

    //   {/* 其他组件 */}
    // </div>
  );
};


export default Home;
