import React from 'react';
import { Button, useClipboard } from '@chakra-ui/react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading
} from '@chakra-ui/react';

const TransactionRow = ({ tx }) => {
  const { hasCopied, onCopy } = useClipboard(tx.tx_id);
  // 将 earning_rate 保留三位小数，如果为空则不显示
  const earningRate = tx.earning_rate !== '' ? parseFloat(tx.earning_rate).toFixed(3) : '';
  const price = tx.price !== '' ? parseFloat(tx.price).toFixed(3) : '';
  const earning = tx.earning !== '' ? parseFloat(tx.earning).toFixed(0) : '';


  return (
    <Tr>
      <Td>{tx.rune_id}</Td>
      <Td>{price}</Td>
      <Td>{tx.amount.toString()}</Td>
      <Td>{earning}</Td>
      <Td>{earningRate}</Td>
      <Td>{(tx.total_sats / 100000000).toFixed(8)}</Td>
      <Td>
        <a href={`https://mempool.space/tx/${tx.tx_id}`} target="_blank" rel="noopener noreferrer">
          {tx.tx_id.slice(0, 4)}...{tx.tx_id.slice(-4)}
        </a>
        <Button onClick={onCopy} ml={2} size="sm">{hasCopied ? 'Copied' : 'Copy'}</Button>
      </Td>
      <Td>{tx.in_idx}</Td>
    </Tr>
  );
};

export default TransactionRow;
