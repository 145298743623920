import React from 'react';
import { IconButton, Badge, Box, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import { FaGasPump } from 'react-icons/fa';
import { LiaGasPumpSolid, LiaRunningSolid, LiaCarSideSolid, LiaRocketSolid } from "react-icons/lia";

function IconButtonWithBadge ({ number }) {
  // Example data for gas rates, replace with actual data
  const gasRates = {
    low: '16 sats/vB',
    medium: '20 sats/vB',
    high: '24 sats/vB'
  };

  return (
    <Menu>
      <MenuButton
        as={Box}
        display="inline-block"
        onMouseEnter={(event) => {
          // Opens the menu when you hover over the MenuButton
          event.currentTarget.click();
        }}
      >
        <IconButton
          pr={7}
          pl={1}
          icon={<LiaGasPumpSolid size="1.5em" />}
          variant="outline"
          aria-label="Gas rates"
        // colorScheme="blue"
        // colorScheme="red"
        // color='gray.50'
        />
        <Badge
          position="relative"
          // colorScheme="red"
          top="0"
          right="8"
          pl={2}
        // borderRadius="full"
        >
          {number}
        </Badge>
      </MenuButton>
      <MenuList>
        <MenuItem minH="48px">
          <LiaRunningSolid size="1.5em" />
          <Text ml="12px">Low: {gasRates.low}</Text>
        </MenuItem>
        <MenuItem minH="40px">
          <LiaCarSideSolid size="1.5em" />
          <Text ml="12px">Medium: {gasRates.medium}</Text>
        </MenuItem>
        <MenuItem minH="40px">
          <LiaRocketSolid size="1.5em" />
          <Text ml="12px">High: {gasRates.high}</Text>
        </MenuItem>
      </MenuList>
    </Menu >
  );
}

export default IconButtonWithBadge;
