import React, { useState, useEffect, useContext } from 'react';
import './Header.css'; // Make sure to import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump, faWallet, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import WalletContext from './WalletContext';
import NavButton from './NavButton';
import {
  Flex,
  Box,
  Input,
  Spacer,
  Button,
  useColorModeValue,
  IconButton,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';


import { SearchIcon } from '@chakra-ui/icons';
import GasMenu from './GasMenu';
import WalletButton from './WalletButton';
const Header = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const color = useColorModeValue('black', 'white');

  return (
    <Flex
      as="header"
      width="full"
      align="center"
      justify="space-between"
      padding="4"
      bg={bgColor}
      color={color}
    >
      <Box>
        {/* Logo or Brand Name Here */}
        <div className="logo-container">
          <img src="/logo.png" alt="Mint365 Logo" />
        </div>

      </Box>
      <Spacer />
      <Flex align="center">
        <Box as="nav">
          {/* Navigation items here */}
          <NavButton to="/">DISCOVER</NavButton>
          <NavButton to="/profile">PROFILE</NavButton>
          {/* <NavButton to="/mint/brc20">MINT</NavButton> */}
          {/* More navigation items */}

        </Box>
        {/* <Badge colorScheme="blue" ml={4}>28</Badge> */}
        <Box>
          <GasMenu />
        </Box>
        <Box>
          <WalletButton />
        </Box>


      </Flex>
    </Flex>
  );
};


// const Header = () => {
//   const [isConnected, setIsConnected] = useState(false);
//   const [walletAddress, setWalletAddress] = useState('');
//   const [showDropdown, setShowDropdown] = useState(false);
//   const { globalWalletAddress, setGlobalWalletAddress } = useContext(WalletContext);

//   const toggleDropdown = () => {
//     setShowDropdown(!showDropdown);
//   };

//   const disconnectWallet = () => {
//     // Logic to disconnect the wallet
//     setIsConnected(false);
//     // Additional cleanup if necessary
//     setWalletAddress('');
//     setGlobalWalletAddress('');
//     setShowDropdown(false); // Hide dropdown on disconnect
//   };

//   async function connectWallet () {
//     if (isConnected) {
//       toggleDropdown();
//     }
//     else {
//       try {
//         if (typeof window.unisat !== 'undefined') {
//           let accounts = await window.unisat.requestAccounts();
//           console.log('connect success', accounts);
//           setIsConnected(true);
//           setWalletAddress(accounts[0]); // This should store the full address
//           setGlobalWalletAddress(accounts[0]);
//         } else {
//           console.log('Please install Unisat wallet!');
//         }
//       } catch (error) {
//         console.error('An error occurred during wallet connection:', error);
//       }
//     }
//   }

//   useEffect(() => {
//     // Set address to globalWalletAddress if it's not empty, otherwise use defaultAddress
//     if (globalWalletAddress !== '') {
//       setIsConnected(true);
//       // Additional cleanup if necessary
//       setWalletAddress(globalWalletAddress);
//     }
//   }, [globalWalletAddress]); // Dependencies array

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (showDropdown && !event.target.closest('.connect')) {
//         setShowDropdown(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showDropdown]);

//   return (
//     <header className="header">
//       <div className="logo-container">
//         {/* Replace with your actual logo */}
//         <img src="/logo.png" alt="GeniiData Logo" />
//       </div>
//       <div className="search-bar">
//         <input type="text" placeholder="Search Address / Tick / Name / Inscription" />
//       </div>
//       <nav className="navigation">
//         <button>
//           <NavLink
//             to="/"
//             className={({ isActive }) =>
//               isActive ? "nav-link nav-link-active" : "nav-link"
//             }
//           >
//             DISCOVER
//           </NavLink>
//         </button>
//         <button>PROFILE</button>
//         <button>INSCRIPTIONS</button>
//         <button>INDEX</button>
//         <button>
//           <NavLink
//             to="/mint/brc20"
//             className={({ isActive }) =>
//               isActive ? "nav-link nav-link-active" : "nav-link"
//             }
//           >
//             MINT
//           </NavLink>
//         </button>
//         {/* <NavLink to="/mint" activeClassName="active">MINT</NavLink> */}

//       </nav>
//       <div className="header-actions">
//         <button className="gas-button">
//           <FontAwesomeIcon icon={faGasPump} />
//           <span className="gas-value">28</span> {/* The number 28 is just an example */}
//         </button>
//         <button className="settings"></button>
//         <button className="connect" onClick={connectWallet}>
//           {isConnected ? (
//             <>
//               {`${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}
//               <FontAwesomeIcon icon={faCaretUp} className={showDropdown ? 'caret-up' : 'caret-down'} />
//             </>
//           ) : (
//             <>
//               <FontAwesomeIcon icon={faWallet} className="connect-icon" /> Connect
//             </>
//           )}

//           {showDropdown && (
//             <div className="dropdown-menu">
//               <ul>
//                 <li>Orders</li>
//                 <li onClick={disconnectWallet}>Disconnect</li>
//               </ul>
//             </div>
//           )}

//         </button>
//       </div>
//     </header>
//   );
// };

export default Header;
