import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const NextBlock = () => {
  const [nextBlockData, setNextBlockData] = useState([]); // 设置 mintData 的 state

  const chartRef = useRef(null);
  let myChart = null;

  const markPoint = {
    data: [
      {
        name: '选择的费率',
        value: 10,
        xAxis: 20,
        yAxis: 33.0
      }
    ]
  };

  const fetchDataFromAPI = async () => {
    // 构建 API 的 URL，包括查询参数
    const url = new URL('https://mempool.space/api/v1/fees/mempool-blocks');
  
    try {
      const response = await fetch(url); // 发送 GET 请求到 API
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const fetchData = async () => {
    try {
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await fetchDataFromAPI(); // 使用当前的 filter 作为参数
      console.log('nextblock', response)
      console.log('feeRange 0', response[0].feeRange[0].toFixed(1))
      console.log('feeRange 1', response[0].feeRange[1].toFixed(1))
      console.log('feeRange 2', response[0].feeRange[2].toFixed(1))
      console.log('feeRange 3', response[0].feeRange[3].toFixed(1))
      console.log('feeRange 4', response[0].feeRange[4].toFixed(1))
      console.log('feeRange 5', response[0].feeRange[5].toFixed(1))
      console.log('feeRange 6', response[0].feeRange[6].toFixed(1))

      setNextBlockData(response); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

    // 使用 useEffect 钩子根据 filter 的变化获取数据
    useEffect(() => {
      fetchData();
    }, []); // 空依赖数组保证只在挂载时运行
  
  useEffect(() => {
    if (nextBlockData.length > 0){
      console.log('next block data', nextBlockData);
      const data = [[0,nextBlockData[0].feeRange[0].toFixed(1)], [15, nextBlockData[0].feeRange[1].toFixed(1)], [30, nextBlockData[0].feeRange[2].toFixed(1)], 
      [50, nextBlockData[0].feeRange[3].toFixed(1)], [65, nextBlockData[0].feeRange[4].toFixed(1)],[80, nextBlockData[0].feeRange[5].toFixed(1)],[100, nextBlockData[0].feeRange[6].toFixed(1)]]
      myChart = echarts.init(chartRef.current);

      var option = {
        // title: {
        //   text: 'NextBlock',
        //   left: 'Left'
        // },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'value',
          boundaryGap: false,
          data: [0,20, 40, 60, 80, 100], // % Weight values
          name: '% Weight',
          nameLocation: 'middle', // or 'end'
          nameGap: 20, // Adjust according to your chart size
          axisLabel: {
            show: true // Make sure the labels are shown
          },
          nameTextStyle: {
            color: '#fff' // Make sure the color is visible against the chart's background
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: 'sats/vB',
          show: true,
          axisLine: {
            show: true // 显示坐标轴线
          },
          axisTick: {
            show: true // 显示坐标轴刻度
          },
          logBase: 10, // 设置对数的基数，默认为10
          splitLine: {
            show: true,
            interval: (index, value) => {
              // 这里可以自定义间隔显示逻辑
              // index 是分割线的索引，value 是分割线的数值
              // 例如，只在对数轴的主要幂级显示分割线
              return value.toString()[0] === '1';
            },      
            lineStyle: {
              color: '#555', // 灰暗色的分割线
              type: 'solid'
            }
          },
          axisLabel: {
            show: true, // 显示标签
          },
          nameTextStyle: {
            color: '#fff' // Make sure the color is visible against the chart's background
          }
        },
        series: [
          {
            name: 'feerate', // 这里是系列名称
            data: data,
            type: 'line',
            areaStyle: {}, // This creates the shaded area under the line
            smooth: true, // This makes the line smooth
            symbol: 'circle', // Defines the shape of the data points
            symbolSize: 8, // Defines the size of the data points
            label: {
              show: true,
              position: 'top'
            },
            // You can also define itemStyle and lineStyle for colors, line width, etc.
            // markPoint: markPoint,
            markPoint : {
              data : [
                {        
                // name: '选择的费率',
                value: 'gas',
                xAxis: 25,
                yAxis: 50
        }
              ]
          }
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        }
      };

      myChart.setOption(option);

      // 组件卸载时清理图表实例
      return () => {
        myChart && myChart.dispose();
      };
  }
  }, [nextBlockData]);

  // 确保在窗口大小变化时图表也能自适应调整
  useEffect(() => {
    const resizeChart = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  return (
    <div>
      <div className="fee-rate-header">Next Block</div>
      <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
    </div>
  );
};

export default NextBlock;