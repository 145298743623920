import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Brc20Mint.css'; // 确保你的样式文件名与此匹配
import MintTable from './Brc20MintTable/MintTable';
import MintProgress from './MintProgress';
import MyComponent from './MyComponent';
import SliderWithTooltip from './SliderWithTooltip';
// const DeployTable = () => {
//   // Deploy表格的内容
//   return <div>...Coming Soon...</div>;
// };

// const TransferTable = () => {
//   // Transfer表格的内容
//   return <div>...Coming Soon...</div>;
// };

// const Brc20Mint = () => {
//   // const [tick, setTick] = useState('eorb');
//   // const [amount, setAmount] = useState(10);
//   // ...其他状态

//   const [activeTab, setActiveTab] = useState('mint');

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   // 根据activeTab的值渲染不同的表格
//   const renderTable = () => {
//     switch (activeTab) {
//       case 'mint':
//         return <MintTable data = {someDataForMintTable}/>;
//       case 'deploy':
//         return <DeployTable />;
//       case 'transfer':
//         return <TransferTable />;
//       default:
//         return null;
//     }
//   };

//   // 处理表单提交
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // 进行mint操作
//   };

//   const location = useLocation();
//   const someDataForMintTable = location.state || {};
//   // const someDataForMintTable = {
//   //   /* ... your data here ... */
//   // };

//   return (
//     <div className="mint-container">
//       <div className="tab-buttons">
//         <div>
//         <button onClick={() => handleTabClick('mint')} className={activeTab === 'mint' ? 'active' : ''}>Mint</button>

//         </div>
//         <div>
//         <button onClick={() => handleTabClick('deploy')} className={activeTab === 'deploy' ? 'active' : ''}>Deploy</button>

//         </div>
//         <div>
//         <button onClick={() => handleTabClick('transfer')} className={activeTab === 'transfer' ? 'active' : ''}>Transfer</button>

//         </div>

//       </div>
//       {renderTable()}
//     </div>
//   );
// };

import { Tabs, TabList, Tab, TabPanels, TabPanel, TabIndicator, Box } from '@chakra-ui/react';

function Brc20Mint () {
  return (
    <Tabs isFitted variant="unstyled" defaultIndex={0} >
      <TabList >
        <Tab _selected={{ color: 'white', bg: 'blue.500' }} borderRadius="lg">Mint</Tab>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }} borderRadius="lg">Deploy</Tab>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }} borderRadius="lg">Transfer</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          {/* Content for Mint */}
          <Box>
            <MintProgress />
            <MyComponent />
            <SliderWithTooltip />
          </Box>
        </TabPanel>
        <TabPanel>
          {/* Content for Deploy */}
        </TabPanel>
        <TabPanel>
          {/* Content for Transfer */}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Brc20Mint;
