import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MintHeader from './Mint'; // 导入Mint页面组件
import Home from './Home'; // 导入首页组件
import Header from './Header'; // Import the Header component
import Brc20Mint from './Brc20Mint';
import Confirm from './Brc20MintTable/Confirm';
import WalletContext from './WalletContext';
// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react';

function App () {
  const [globalWalletAddress, setGlobalWalletAddress] = useState('');

  return (
    <ChakraProvider>
      <WalletContext.Provider value={{ globalWalletAddress, setGlobalWalletAddress }}>
        <Router>
          <div className="app">
            <Header />
            <Routes>
              <Route path="/mint" element={<MintHeader />}>
                <Route path="brc20" element={<Brc20Mint />} />
                <Route path="confirm" element={<Confirm />} />
              </Route>
              <Route path="/" element={<Home />}>
              </Route>
              {/* Define other routes here */}
            </Routes>
          </div>
        </Router>
      </WalletContext.Provider>
    </ChakraProvider>
  );
}


export default App;
