import { Button } from '@chakra-ui/react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

function NavButton ({ to, children, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Button
      as={Link}
      to={to}
      variant='ghost'
      // variant={match ? 'solid' : 'ghost'}
      colorScheme={match ? 'blue' : 'gray'}
      {...props}
    >
      {children}
    </Button>
  );
}

export default NavButton;