import React, { useState } from 'react';

import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const WalletButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [connectingWallet, setConnectingWallet] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const connectWallet = async (walletName) => {
    try {
      setIsLoading(true);

      // Set the wallet we're currently connecting to
      setConnectingWallet(walletName);

      try {
        if (typeof window.unisat !== 'undefined') {
          let accounts = await window.unisat.requestAccounts();
          console.log('connect success', accounts);
          setWalletAddress(accounts[0]); // This should store the full address
          // setIsConnected(true);
          // setGlobalWalletAddress(accounts[0]);
        } else {
          console.log('Please install Unisat wallet!');
        }
      } catch (error) {
        console.error('An error occurred during wallet connection:', error);
      }

      // Logic to connect to Unisat wallet goes here
      // This is usually done via a wallet SDK or API
      // For example, using web3.js:
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // setIsConnected(accounts.length > 0);

      // After successful connection, you can set the state to true
      setIsConnected(true);
      setIsLoading(false);
      onClose(); // Close modal if needed
    } catch (error) {
      console.error('Error connecting to Unisat wallet:', error);
      setIsConnected(false);
    } finally {
      setIsLoading(false);
    }

    // Simulate the async connection process
    setTimeout(() => {
      // Connection finished
      setConnectingWallet(null);
      // Handle successful connection here
      onClose(); // Close modal if needed
    }, 30000); // 3 seconds for demo purposes
  };

  const disconnectWallet = () => {
    // Logic to disconnect the wallet
    setIsConnected(false);
    // Additional cleanup if necessary
    setWalletAddress('');
    setIsLoading(false);
    // setShowDropdown(false); // Hide dropdown on disconnect
  };

  return (
    <Box>
      {isConnected && walletAddress ? (
        // <Menu>
        //   <MenuButton as={Button} colorScheme="blue" ml={4} rightIcon={<ChevronDownIcon />}>
        //     {`${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}
        //   </MenuButton>
        //   <MenuList>
        //     <MenuItem>Orders</MenuItem>
        //     <MenuItem>Sign In on Mobile</MenuItem>
        //     <MenuItem onClick={disconnectWallet}>Disconnect</MenuItem>
        //   </MenuList>
        // </Menu>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton as={Button} colorScheme="blue" ml={4} rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}>
                {`${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}
              </MenuButton>
              <MenuList>
                {/* MenuItems go here */}
                <MenuItem>Orders</MenuItem>
                <MenuItem>Sign In on Mobile</MenuItem>
                <MenuItem onClick={disconnectWallet}>Disconnect</MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      ) : (
        <Button colorScheme="blue" ml={4} onClick={onOpen}>
          Connect
        </Button>
      )}

      {/* The Modal for initial connection */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Connect Wallet</ModalHeader>
          <ModalBody>
            <Button w="full" mt="4" onClick={() => connectWallet('Unisat')}>
              {isLoading ? 'Connecting...' : 'Connect Unisat'}
              {connectingWallet === 'Unisat' && (
                <Spinner thickness="2px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="sm" />
              )}
            </Button>
            {/* Repeat for other wallets */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WalletButton;
