import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';

function SliderWithTooltip () {
  const [value, setValue] = useState(1);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (value) => setValue(value);

  return (
    <Flex align="center">
      <Slider
        flex="1"
        id="slider"
        value={value}
        min={1}
        max={1000}
        focusThumbOnChange={false}
        onChange={handleChange}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="teal.500"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={`${value}`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
      <NumberInput
        maxW="100px"
        ml={2}
        value={value}
        min={1}
        max={1000}
        onChange={handleChange}
      >
        <NumberInputField bg="gray.300" min={1} max={1000} />
        {/* <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper> */}
      </NumberInput>
    </Flex>
  );
}


export default SliderWithTooltip;