import React from 'react';
import './InscriptionsList.css'; // 确保引入了正确的CSS文件

const InscriptionsList = ({ inscriptions }) => {
  console.log('inscriptions:',inscriptions)
  return (
    <div>
      <h2>{inscriptions.length} inscriptions are about to be created</h2>
      <div className="inscriptions-container">
      {/* <h2>200 inscriptions are about to be created</h2> */}
      <div className="inscriptions-list">
        {inscriptions.map((inscription, index) => (
          <div key={index} className="inscription-item">
            <span>{index + 1}</span>
            <pre>{inscription}</pre>
            <span>{inscription.length} B</span>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default InscriptionsList;
