import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

function MyComponent () {
  // Assuming you have state hooks to manage the values
  const [tick, setTick] = useState('');
  const [amount, setAmount] = useState(1);
  const [sliderValue, setSliderValue] = useState(1);

  return (
    <VStack spacing={4} align="stretch">
      <FormControl id="tick">
        <FormLabel>Tick</FormLabel>
        <Input placeholder="Enter tick" value={tick} onChange={(e) => setTick(e.target.value)} />
      </FormControl>

      <FormControl id="amount">
        <FormLabel>Amount</FormLabel>
        <NumberInput min={1} max={1000000} value={amount} onChange={(valueString) => setAmount(valueString)}>
          <NumberInputField bg="gray.300" _hover={{ bg: 'gray.400' }} _focus={{ bg: 'gray.300' }} placeholder="Enter amount" />
        </NumberInput>
      </FormControl>

      {/* <FormControl id="bulk-mint">
        <FormLabel>Bulk Mint</FormLabel>
        <Slider
          id="slider"
          defaultValue={1}
          min={1}
          max={1000}
          value={sliderValue}
          onChange={(value) => setSliderValue(value)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="tomato" as={Text}>{sliderValue}</Box>
          </SliderThumb>
        </Slider>
      </FormControl> */}
    </VStack>
  );
}

export default MyComponent;