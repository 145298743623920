import React,{useState, useEffect,useContext} from 'react';
import './FeeDetails.css'; // Make sure to import the CSS file
import WalletContext from '../WalletContext';

function FeeDetails({ selectedRateNum,inscriptions }) {
  // Placeholder data
  // const satInInscription = 546;
  // const networkFee = 178000;
  // const serviceBaseFee = 1499;
  const feeBySize = 0;
  // const totalSats = networkFee + serviceBaseFee + feeBySize;
  // const estimatedUSD = (totalSats / 100000000) * 42000; // Assuming 1 Bitcoin = 42000 USD
  // State to track whether the advanced options are shown
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [customSats, setCustomSats] = useState(546);
  const [txVByte, setTxVByte] = useState(0);
  const [btcPrice, setBtcPrice] = useState(0);

  const [address, setAddress] = useState('');
  const [orderInfo, setOrderInfo] = useState({
    orderID:'',
    receiverAddr:'',
  });

  const { globalWalletAddress, setGlobalWalletAddress } = useContext(WalletContext);
  
  useEffect(() => {
    // Set address to globalWalletAddress if it's not empty, otherwise use defaultAddress
    setAddress(globalWalletAddress);
  }, [globalWalletAddress]); // Dependencies array

  // Handler to toggle the advanced options
  const handleToggleAdvanced = () => {
    setShowAdvanced(prevShowAdvanced => !prevShowAdvanced);
  };

  const handleSatsSliderChange = (e) => {
    setCustomSats(e.target.value);
  };

  const createOrderFromAPI = async (inscriptions) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "mint_type": "brc20",
      "content_type": "text/plain;charset=utf-8",
      "body": inscriptions[0],
      "count": inscriptions.length,
      "gas_fee":selectedRateNum,
      "total_sats":inscriptions.length*customSats+txVByte*selectedRateNum,
      "sats_in_inscription":customSats,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("http://127.0.0.1:80/api/v1/btc/ordi/inscribe/order", requestOptions)
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const fetchDataFromAPI = async (inscriptions) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "mint_type": "brc20",
      "content_type": "text/plain;charset=utf-8",
      "body": inscriptions[0],
      "count": inscriptions.length
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("http://127.0.0.1:80/api/v1/btc/ordi/mint-fee", requestOptions)
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const createInscribeOrder = async (inscriptions) => {
    try {
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await createOrderFromAPI(inscriptions); // 使用当前的 filter 作为参数
      console.log('create order resp',response.data)
      setOrderInfo({
        orderID:response.data.result.order_id,
        receiverAddr:response.data.result.receive_address
      })
      // setAmount(response.data.per_mint); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

  const fetchData = async (inscriptions) => {
    try {
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await fetchDataFromAPI(inscriptions); // 使用当前的 filter 作为参数
      console.log('mint fee resp',response.data)
      setTxVByte(response.data.result.v_byte)
      // setAmount(response.data.per_mint); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

  const fetchBtcPriceFromAPI = async () => {
    // 构建 API 的 URL，包括查询参数
    const url = new URL('http://127.0.0.1:80/api/v1/btc/price');
  
    try {
      const response = await fetch(url); // 发送 GET 请求到 API
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const fetchBtcPriceData = async () => {
    try {
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await fetchBtcPriceFromAPI(); // 使用当前的 filter 作为参数
      console.log(response.data)
      setBtcPrice(response.data.price); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching btc price data:', error);
    }
  };

  // 使用 useEffect 钩子根据 filter 的变化获取数据
  useEffect(() => {
    if (inscriptions.length !==0){
      fetchData(inscriptions);
    };
    fetchBtcPriceData();
  }, []); // 空依赖数组保证只在挂载时运行

  async function connectWallet () {
    if (address === '') {
      try {
        if (typeof window.unisat !== 'undefined') {
          let accounts = await window.unisat.requestAccounts();
          console.log('connect success', accounts);
          setGlobalWalletAddress(accounts[0]);
        } else {
          console.log('Please install Unisat wallet!');
        }
      } catch (error) {
        console.error('An error occurred during wallet connection:', error);
      }
    } else {
      // create inscribe order
      await createInscribeOrder(inscriptions)
      console.log('order info',orderInfo);
      try {
        let txid = await window.unisat.sendBitcoin(orderInfo.receiverAddr,inscriptions.length*customSats+txVByte*selectedRateNum);
        console.log(txid)
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div className="fee-details">
      <div className="estimated-rates">Estimated rates for reference only.</div>
      <div className="fee-line">
          <span>Sats In Inscription</span>
          <button onClick={handleToggleAdvanced} className="advanced">Custom (Min is 330)</button>
          <div>
          <span>{inscriptions.length} × {customSats} sats}</span> 
          <span  className="fee-price">~${(inscriptions.length*customSats*btcPrice/100000000).toFixed(2)}</span> 
          </div>
      </div>
      <div className="fee-line">
      {showAdvanced && (
            <div className="sats-slider-container">
              <input
                type="range"
                min="330"
                max="10000"
                value={customSats}
                className="sats-slider"
                onChange={handleSatsSliderChange}
              />
              <div className="sats-slider-value">{customSats}</div>
            </div>
          )}
      </div>
      <div className="fee-line">
        <span>Network Fee</span>
        <div>
        <span>~{txVByte*selectedRateNum} sats</span>
        <span className="fee-price">~${(txVByte*selectedRateNum*btcPrice/100000000).toFixed(2)}</span>

        </div>
      </div>
      <hr class="divider" />

      <div className="fee-line">
        <span>Service Base Fee</span>
        <span>Free</span>
      </div>
      <div className="fee-line">
        <span>+ Fee by Size</span>
        <span>{feeBySize === 0 ? 'Free' : `~${feeBySize} sats`}</span>
      </div>
      <hr class="divider" />

      <div className="fee-line fee-total">
        <span>Total</span>
        <div>
          <span>~{inscriptions.length*customSats+txVByte*selectedRateNum} sats</span>
          <span className="fee-price">~${((inscriptions.length*customSats+txVByte*selectedRateNum)*btcPrice/100000000).toFixed(2)}</span>
        </div>
      </div>
      {/* <div className="agreement">
        <input type="checkbox" id="agree" />
        <label htmlFor="agree">I have read and agreed to the risk warning</label>
      </div> */}
      <button className="submit" onClick={connectWallet}>{address === ''?'Connect Wallet':'Submit'}</button>
    </div>
  );
}

export default FeeDetails;
