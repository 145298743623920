import React, { useState,useEffect } from 'react';
import './FeeRate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faRocket, faBolt, faStar } from '@fortawesome/free-solid-svg-icons';

const FeeRate = ({ setSelectedRateNum }) => {
  const [selectedRate, setSelectedRate] = useState('fast');
  const [customRate, setCustomRate] = useState(32);
  const [feeRateData, setFeeRateData] = useState([]); // 设置 mintData 的 state


  const fetchDataFromAPI = async (filter) => {
    // 构建 API 的 URL，包括查询参数
    const url = new URL('https://mempool.space/api/v1/fees/recommended');
  
    try {
      const response = await fetch(url); // 发送 GET 请求到 API
      if (!response.ok) {
        // 如果响应状态码不是 2xx, 抛出错误
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // 解析 JSON 响应体
      return data; // 返回解析后的数据
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // 抛出错误以便调用者处理
    }
  };

  const fetchData = async () => {
    try {
      const rates = [
        { key: 'slow', value: 32, label: 'Slow', icon: faBicycle},
        { key: 'fast', value: 32, label: 'Fast', icon: faRocket},
        { key: 'hyper', value: 42, label: 'Hyper', icon: faBolt },
        { key: 'custom', value: customRate, label: 'Custom', icon: faStar },
      ];
    
      // 假设 `fetchDataFromAPI` 是调用后端 API 的函数
      const response = await fetchDataFromAPI(); // 使用当前的 filter 作为参数
      /**
       * {
    "fastestFee": 27,
    "halfHourFee": 26,
    "hourFee": 25,
    "economyFee": 25,
    "minimumFee": 21
}
       */
      console.log(response)
      rates[0].value = response.hourFee
      rates[1].value = response.halfHourFee
      rates[2].value = response.fastestFee
      rates[3].value = response.minimumFee

      setFeeRateData(rates)
      // setMintData(response.data.result); // 假设响应数据在 `response.data` 中
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

  // 使用 useEffect 钩子根据 filter 的变化获取数据
  useEffect(() => {
    fetchData();
  }, []); // 空依赖数组保证只在挂载时运行

  const handleRateClick = (key, value) => {
    setSelectedRate(key);
    if (key === 'custom') {
      // If custom is selected, allow the user to use the slider
      setCustomRate(value);
      setSelectedRateNum(value);
    } else {
      setSelectedRateNum(value);
    }
  };

  const handleSliderChange = (e) => {
    setCustomRate(e.target.value);
    setSelectedRateNum(e.target.value);
    setSelectedRate('custom');
  };

  return (
    <div className="fee-rate-container">
      <div className="fee-rate-header">Fee Rate</div>
      <div className="fee-rate-options">
        {feeRateData.map((rate) => (
          <button
            key={rate.key}
            className={`fee-rate-button ${selectedRate === rate.key ? 'selected' : ''}`}
            onClick={() => handleRateClick(rate.key, rate.value)}
          >
          <FontAwesomeIcon icon={rate.icon} />
            {rate.key !== 'custom' && (
              <div className="fee-rate-value">{rate.value} sats/vB</div>
            )}

            <div className="fee-rate-label">{rate.label}</div>
          </button>
        ))}
      </div>
      {selectedRate === 'custom' && (
        <div className="fee-rate-slider-container">
          <input
            type="range"
            min="1"
            max="1000"
            value={customRate}
            className="fee-rate-slider"
            onChange={handleSliderChange}
          />
          <div className="fee-rate-slider-value">{customRate}</div>
        </div>
      )}
    </div>
  );
};

export default FeeRate;

