import React,{useState} from 'react';
import FeeRate from './FeeRate';
import NextBlock from './NextBlock';
import FeeDetails from './FeeDetails';
import './FeeContainer.css'
function FeeContainer({inscriptions}) {
  const [selectedRateNum, setSelectedRateNum] = useState(10);

  return (
    <div className="fee-container">
      <div className="left-side">
        {/* Pass setSelectedRate to FeeRate so it can update the selectedRate */}
        <FeeRate setSelectedRateNum={setSelectedRateNum} />
        {/* Pass selectedRate to NextBlock to use the selected rate */}
        <NextBlock selectedRateNum={selectedRateNum} />
      </div>
      <div className="right-side">
        {/* Pass selectedRate to FeeDetails to use the selected rate */}
        <FeeDetails selectedRateNum={selectedRateNum} inscriptions={inscriptions} />
      </div>
    </div>
  );
}


export default FeeContainer;
