import { Box, Progress, Text, Tooltip, Flex } from '@chakra-ui/react';

function MintProgress () {
  // Example values, replace with your actual data or state
  const percentage = 50;
  const progressDetails = {
    progress: '2,497,000,000 / 45,000,000,000',
    confirmedBefore24h: '1,000,000',
    confirmedIn1To24h: '2,496,000,000',
    confirmedIn1h: '0',
    inMemoryPool: '0',
  };

  const tooltipLabel = (
    <>
      <Text>Progress: {progressDetails.progress}</Text>
      <Text>Confirmed before 24h: {progressDetails.confirmedBefore24h}</Text>
      <Text>Confirmed in 1-24h: {progressDetails.confirmedIn1To24h}</Text>
      <Text>Confirmed in 1h: {progressDetails.confirmedIn1h}</Text>
      <Text>In memory pool: {progressDetails.inMemoryPool}</Text>
    </>
  );

  return (
    <Box bg="white" p={4} borderRadius="lg" boxShadow="md">
      <Text color="gray.800" fontWeight="semibold">
        450x | #44563660
      </Text>
      <Tooltip hasArrow label={tooltipLabel} bg="gray.300" color="black">
        <Flex alignItems="center">
          <Progress colorScheme="green" value={percentage} size="sm" width="full" mr={2} />
          <Text color="gray.800" fontSize="xl" fontWeight="bold">
            {percentage.toFixed(2)}%
          </Text>
        </Flex>
      </Tooltip>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text color="gray.600" mt={2} fontSize="sm">
          Last Block #: 833580
        </Text>
      </Box>
    </Box>
  );
}

export default MintProgress;
