import React from 'react';
import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton, Text } from '@chakra-ui/react';
import { FaGasPump } from 'react-icons/fa';
import IconButtonWithBadge from './IconButtonWithBadge';
function GasMenu () {

  return (
    <Box>
      <Menu>
        <IconButtonWithBadge number={16} />
      </Menu>
    </Box>
  );
}

export default GasMenu;