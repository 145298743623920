// Mint.js
import React from 'react';
import { Routes, Route, NavLink, Outlet } from 'react-router-dom';
// import MetaSubPage from './MetaSubPage'; // META页面的组件
// import TextSubPage from './TextSubPage'; // TEXT页面的组件
// import FileSubPage from './FileSubPage'; // FILE页面的组件
import Brc20Mint from './Brc20Mint';
import './Mint.css'; // 确保你的样式文件与此匹配
import MintNavigationBar from './MintNavigationBar';
// const Mint = () => {
//   return (
//     <div>
//   <MintNavigationBar />
//     </div>
//   );
// };

// export default Mint;

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Box,
  Text
} from '@chakra-ui/react';

function MintHeader () {
  // If you have a separate header component, this Tabs component would go just below it in your layout
  return (
    <VStack>
      <Tabs variant="unstyled" defaultIndex={0} isFitted>
        <TabList borderColor="blue.500">
          <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid' }}>BRC20</Tab>
          <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid' }}>META</Tab>
          <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid' }}>TEXT</Tab>
          <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid' }}>FILE</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <Brc20Mint></Brc20Mint>
            </Box>
            {/* Content for BRC20 */}
          </TabPanel>
          <TabPanel>
            {/* Content for META */}
          </TabPanel>
          <TabPanel>
            {/* Content for TEXT */}
          </TabPanel>
          <TabPanel>
            {/* Content for FILE */}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Rest of the page content */}
      {/* <Outlet /> 子页面将在这里渲染 */}

    </VStack>
  );
}

export default MintHeader;