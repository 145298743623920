import React, { useState, useContext,useEffect } from 'react';
import './ReceiveInput.css'; // 确保引入了正确的CSS文件
import WalletContext from '../WalletContext';

const ReceiveInput = ({ defaultAddress }) => {
  const [address, setAddress] = useState(defaultAddress);

  const handleChange = (event) => {
    setAddress(event.target.value);
  };
  const { globalWalletAddress } = useContext(WalletContext);
  
  useEffect(() => {
    // Set address to globalWalletAddress if it's not empty, otherwise use defaultAddress
    setAddress(globalWalletAddress || defaultAddress);
  }, [globalWalletAddress, defaultAddress]); // Dependencies array

  return (
    <div className="receive-input">
      <label htmlFor="receiveAddress">Receive</label>
      <input
        type="text"
        id="receiveAddress"
        value={address}
        onChange={handleChange}
      />
    </div>
  );
};

export default ReceiveInput;
