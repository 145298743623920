import React from 'react';
import { Box, Text, Heading, Table, TableContainer, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import TransactionRow from './TransactionRow'; // 确保正确导入

const RuneTransactions = ({ runeTxsData }) => {
  return (
    <Box p={5}>
      {runeTxsData.map((runeData, idx) => (
        <Box key={idx} mb={8}>


          <Heading size="lg">
            {runeData.rune_name} -
            floor_price(me): <a href={`https://magiceden.io/runes/${runeData.rune_name}`} target="_blank" rel="noopener noreferrer">{runeData.floor_price}</a> -
            pending_tx(me): <a href={`https://magiceden.io/runes/${runeData.rune_name}`} target="_blank" rel="noopener noreferrer">{runeData.pending_tx}</a>
          </Heading>

          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Rune ID</Th>
                  <Th>Price</Th>
                  <Th>Amount</Th>
                  <Th>Earn(Sats)</Th>
                  <Th>Earn Rate(%)</Th>
                  <Th>Total Value(Btc)</Th>
                  <Th>Transaction ID</Th>
                  <Th>Input Index</Th>
                </Tr>
              </Thead>
              <Tbody>
                {runeData.rune_txs.map((tx) => (
                  <TransactionRow key={`${tx.tx_id}-${tx.in_idx}`} tx={tx} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default RuneTransactions;


